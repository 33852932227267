import React, { useState, useEffect, useRef } from "react";
import image from "./assets/flower-min.jpg";
import song from "./assets/song.mp3";

const AudioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isTextVisible1, setIsTextVisible1] = useState(false);
  const [isTextVisible2, setIsTextVisible2] = useState(false);
  const audioRef = useRef(null);
  const touchStartRef = useRef(null);
  const isTouchingAudioRef = useRef(false);

  const handleImageClick = () => {
    setIsPlaying(!isPlaying);
  };

  const handleButtonClick1 = () => {
    setIsTextVisible1(!isTextVisible1);
  };

  const handleButtonClick2 = () => {
    setIsTextVisible2(!isTextVisible2);
  };

  useEffect(() => {
    const audioElement = audioRef.current;

    if (isPlaying) {
      audioElement.play();
      document.addEventListener("touchstart", handleTouchStart);
      document.addEventListener("touchend", handleTouchEnd);
    } else {
      audioElement.pause();
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchend", handleTouchEnd);
    }

    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isPlaying]);

  const handleTouchStart = (event) => {
    touchStartRef.current = Date.now();
    const touchTarget = event.target;
    isTouchingAudioRef.current = touchTarget === audioRef.current;
  };

  const handleTouchEnd = (event) => {
    const touchEnd = Date.now();
    const touchDuration = touchEnd - touchStartRef.current;
    const audioElement = audioRef.current;

    if (
      touchDuration < 300 &&
      isTouchingAudioRef.current &&
      !audioElement.paused
    ) {
      setIsPlaying(false);
    }
  };

  return (
    <div className="audio-player">
      <div className="image-container">
        <img src={image} alt="Flower" onClick={handleImageClick} />
      </div>
      <audio ref={audioRef} src={song} loop />
      {/* {isPlaying && <audio src={song} autoPlay loop />} */}
      <div className="button bottom left" onClick={handleButtonClick1}>
        (lyrics)
      </div>
      {isTextVisible1 && (
        <div className="text top left">
          <span className="highlight">
            hope that you can turn it around
            <br />
            hope that you can turn it around
            <br />
            <br />
            look at all these flowers
            <br />
            growing on the tears you shed
            <br />
            just look at all these flowers
            <br />
          </span>
        </div>
      )}
      <div className="button bottom right" onClick={handleButtonClick2}>
        (gardeners)
      </div>
      {isTextVisible2 && (
        <div className="text top right">
          <span className="highlight">
            people who have been watering this flower:
            <br />
            <br />
            anacá, anna climent, anna pujol, bernat mola, carol lakiszyk, cesc
            colomina, cesc hernandez, carlos anselmo, claud hernandez, èric
            pons, gerard gamundi, gerard rodriguez muñoz, gerard vidal barrena,
            hector prats, helena calafell, ignasi àvila padró, joana vilapuig,
            joan esteve, júlia moll, laura ramis, lola errando, luca roma, marc
            sala, maria jou sol, marta bessa, martí atance, mireia vilapuig,
            núria frías, paula catalan, pau canivell, pau vidal, tom sturgess,
            tura, txiki blasi & diego garrido
            <br />
          </span>
        </div>
      )}
    </div>
  );
};

export default AudioPlayer;
